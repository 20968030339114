<template>
  <searchable-object-selector ref="selector" :objects="sortedClients" :disabled="disabled" :placeholder="placeholder"
    @input="input" :value="value" :loading="$apollo.loading" :state="state" />
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'client-selector',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default() {
        return false;
      }
    },
    disabled: {
      Boolean,
      default() {
        return false;
      }
    },
    size: String,
    placeholder: String,
    state: {
      Boolean,
      default() {
        return null;
      }
    }
  },
  apollo: {
    clients: {
      query: gql`query {
        companies { clients { id, name, accountStatus } }
      }`,
      update: (data) => data.companies.clients
    }
  },
  computed: {
    sortedClients() {
      return this.clients ? this.clients.slice().sort((a, b) => a.name.localeCompare(b.name)) : [];
    }
  },
  methods: {
    input(v) {
      this.$emit('input', v);
      this.$emit('change', v);
    },
    selectById(id) {
      this.$refs.selector.selectById(id);
    }
  },
  mounted() {
    this.$apollo.queries.clients.refetch();
  }
};
</script>
